import AnchorData from "@/includes/logic/Modules/modules/modules/AnchorsHandlerModule/AnchorData";

import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { plainToInstance } from "class-transformer";

const iterableItemFactory = new IterableItemFactory()

export function getAnchorInstance() {
  return plainToInstance(AnchorData, {
    triggers: [],
    reaction: [],
    delete_previous: false,
    enabled: false,
    strict: false,
    send_as_reply: false,
    remove_request: false,
  })
}

export function frontendAnchors(anchors: Array<AnchorData>) {
  return anchors.map(a => iterableItemFactory.create(a))
}

export function rawAnchors(items: Array<IterableListItem<AnchorData>>) {
  return items.map(i => i.value)
}

export function createAnchor() {
  return iterableItemFactory.create(getAnchorInstance())
}
